#content{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#errorMessage{
  height: 10px;
  color: red;
  text-align: center;
}